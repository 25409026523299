import Vue from 'vue';
import Echo from 'laravel-echo';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import VueLuxon from 'vue-luxon';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueFormulate from '@braid/vue-formulate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import './assets/styles/index.css';
import router from './router';
import store from './store';
import App from './App.vue';

Vue.prototype.$axios = axios;
Vue.use(Vuelidate);
Vue.use(VueLuxon, {
  input: {
    zone: 'Europe/Berlin',
    format: 'sql',
  },
  output: 'date_short',
});
Vue.use(VueToast, {
  position: 'bottom-right',
  dismissible: true,
});
Vue.use(VueFormulate);
Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
  wsPort: 6001,
  wssPort: 6001,
  forceTLS: false,
  // encrypted: true,
  // forceTLS: true,
  disableStats: true,
});

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

store.dispatch('auth/me').then(() => {
  new Vue({
    router,
    store,
    render(h) {
      return h(App);
    },
    created() {
      document.title = 'Medienhaus Eifel - Kanban';
    },
  }).$mount('#app');
});

<template>
  <div class="py-8 mx-auto h-full">
<!--    <button class="ml-7 bg-blue-600 py-1 px-3 rounded-3xl text-white">-->
<!--      Neue Karte</button>-->
    <div class="flex overflow-y-hidden h-full w-full mx-auto p-6 mb-10 main">
      <div
        v-for="column in columns"
        :key="column.id"
        class="pr-3 pl-0.5 py-3 mr-4 bg-gray-100 rounded-lg h-full"
        :class="{'w-5': !column.is_open, 'column-width': column.is_open}"
      >
        <Column :column="column" :cards="column.cards" class="h-full"
                :statusList="statusList"
                @cardchange="updateCard"
                @reload="get"
        ></Column>
      </div>
    </div>
  </div>
</template>

<script>
import Column from '@/components/Column.vue';
// import draggable from 'vuedraggable';
// import TaskCard from '../components/TaskCard.vue';

export default {
  name: 'Home',
  components: {
    Column,
  },
  computed: {
    userState() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      modal: false,
      columns: [],
      cards: [],
      statusList: [],
    };
  },
  methods: {
    get() {
      this.$axios.get('columns/profiles',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.columns = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    statusIndex() {
      this.$axios.get('status',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.statusList = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    // getCards() {
    //   this.$axios.get('http://homestead.test/api/cards')
    //     .then((response) => {
    //       this.cards = response.data.data;
    //     })
    //     .catch(() => {
    //       // console.log(error);
    //     });
    // },
    getCardsForColumn(col) {
      const columnCards = [];
      for (let i = 0; i < this.cards.length; i += 1) {
        if (this.cards[i].column_id === col.id) {
          columnCards.push(this.cards[i]);
        }
      }
      return columnCards;
    },
    updateCard(card) {
      for (let i = 0; i < this.columns.length; i += 1) {
        for (let j = 0; j < this.columns[i].cards.length; j += 1) {
          if (card.id === this.columns[i].cards.id
            && card.column_id !== this.columns[i].id) {
            this.columns[i].cards.splice(j, 1);
          } else if (card.id === this.columns[i].cards.id
            && card.column_id === this.columns[i].id) {
            this.columns[i].cards.splice(j, 1, this.columns[i].cards[j]);
          }
        }
      }
    },
  },
  mounted() {
    this.get();
    this.statusIndex();
    window.Echo.channel('column.deleted').listen('ColumnDeleted', () => {
      this.get();
    });
  },
  watch: {
    userState() {
      this.get();
    },
  },
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 400px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.sortable-fallback{
  /*visibility:hidden;*/
}
</style>

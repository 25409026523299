<template>
  <div class="flex-1 flex flex-col block h-0.5 w-full divide-y divide-gray-200">
    <div class="flex-none flex flex-row mt-1 w-full">
      <div class="flex-none w-16 text-gray-700 font-semibold font-sans text-sm
                      tracking-wide">
        Anz.
      </div>
      <div class="flex-1 text-gray-700 font-semibold font-sans text-sm
                      tracking-wide">
        Bezeichnung
      </div>
    </div>
    <div class="flex-grow flex flex-col w-full min-h-24 overflow-auto z-10 pb-5
    divide-y divide-gray-200">
      <div v-for="item in orderItems" :key="item.id"
           class="flex flex-row py-2">
        <div class="flex-none w-16 text-gray-700 font-sans text-sm text-left
                          align-top tracking-wide">
          <span v-if="!selectable">{{ item.pl_quantity }}</span>
          <FormulateInput
            v-if="selectable"
            input-class="ml-2 transform scale-150"
            label=""
            type="checkbox"
            :value="item"
            v-model="slotOrderItems"
          />
<!--          <input v-if="selectable" type="number" class="p-1 border
focus-within:border-blue-500-->
<!--          focus-within:text-blue-500 transition-all duration-500 rounded w-12 outline-none"-->
<!--                 :value="item.pl_quantity" min="0" :max="item.pl_quantity" />-->
        </div>
        <div class="flex-1 text-gray-700 font-sans text-sm tracking-wide"
             v-html="item.pl_name">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductTable',
  props: {
    card: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slotOrderItems: [],
    };
  },
  computed: {
    orderItems() {
      const items = [];
      this.card.order_items.forEach((o) => {
        if (o.pl_type !== 6) {
          // eslint-disable-next-line no-param-reassign
          o.pl_name = o.pl_name.replace(/\s\|\s/g, '<br />');
          items.push(o);
        }
      });
      return items;
    },
  },
  methods: {
  },
  mounted() {
  },
  watch: {
    slotOrderItems: {
      deep: true,
      handler(value) {
        this.$emit('addSlotOrderItems', value);
      },
    },
  },
};
</script>

<style scoped>

</style>
